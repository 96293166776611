import React from 'react';
import {
    AcademicCapIcon, ArrowTopRightOnSquareIcon,
    BookOpenIcon, BuildingLibraryIcon, ChartBarIcon, DocumentArrowDownIcon, EnvelopeIcon,
    MapPinIcon
} from '@heroicons/react/20/solid';

import { SocialIcon } from 'react-social-icons';
import { copyToClipboard } from '../utils/utils';
import {
    CheckBadgeIcon,
    PencilIcon,
} from '@heroicons/react/20/solid';
import SkillsModal from '../components/skills-modal';
import CoursesModal from '../components/courses-modal';
import AnimatedBorder, { muted_border } from '../components/animated-border';
import SkillsGrid from '../components/skills-grid';

function Dashboard() {
    return (
        <div className='w-full min-h-screen flex justify-center lg:p-2 py-2 p-0'>
            <CoursesModal />

            <div className='w-full max-w-7xl'>
                <div className='w-full grid grid-cols-12 gap-2'>
                    <div className='col-span-12 bg-base-300 card'>
                        <div className={`card-body md:px-12 md:py-8 p-4 group ${muted_border}`}>
                            <AnimatedBorder />
                            <div className='hero flex md:flex-row flex-col justify-center items-center md:gap-4 gap-2'>
                                <div className='avatar'>
                                    <div className='md:w-60 w-48 rounded-full border-8 border-base-content border-opacity-25'>
                                        <img
                                            src='/images/avatar.jpg'
                                            alt={'Headshot of Liam Burks'}
                                        />
                                    </div>
                                </div>

                                <div className='mx-2' />

                                <div className='flex flex-col h-full justify-center w-full'>
                                    <div className='flex md:flex-row flex-col gap-2 items-center'>
                                        <h2 className='md:text-6xl text-4xl w-full md:text-start text-center'>
                                            Liam Burks
                                        </h2>
                                        <div className='flex items-center gap-2'>
                                            <a
                                                href={'https://liam-burks.s3.eu-west-1.amazonaws.com/LJBurks_12-06-2024.pdf'}
                                                target={'_blank'}
                                                className='btn btn-circle btn-primary hover:bg-primary flex items-center justify-center tooltip tooltip-left normal-case'
                                                data-tip={'Download CV (.pdf)'}
                                            >
                                                <DocumentArrowDownIcon className='text-base-100 w-6 h-6' />
                                            </a>
                                            <SocialIcon
                                                target={'_blank'}
                                                url='https://www.linkedin.com/in/liamjamesburks'
                                            />
                                        </div>

                                    </div>
                                    <div className='my-2' />
                                    <span className='text-sm'>
                                        Computer Engineering graduate with a passion for software engineering and full-stack development. I'm a fast learner with a proclivity for seeking out new technology in the pursuit of improving projects I am working on. I have 3 years of experience working on production grade systems as a full stack developer, with a focus on efficiency in data transfer due to the enormous volume of data these systems consume.
                                        I spend a significant amount of my free time learning new frameworks and languages in order to build software tools which solve real-world problems and provide value to their users.
                                    </span>
                                    <div className='divider my-2 ' />
                                    <SkillsGrid />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='lg:col-span-6 col-span-12 bg-base-300 card'>
                        <div className={`card-body group md:px-12 md:py-8 p-4 ${muted_border}`}>
                            <AnimatedBorder />

                            <div className='flex md:flex-row flex-col gap-2 w-full justify-between md:items-center items-start'>
                                <h3 className='card-title'>
                                    Professional Experience
                                </h3>
                    
                                <div className='badge badge-primary badge-lg font-bold'>
                                    Software Engineer
                                </div>
                            </div>
                    
                            <div className='divider my-0' />
                    
                            <div className='flex md:flex-row flex-col gap-2 w-full md:justify-between justify-start items-start md:items-center'>
                                <a
                                    href={'https://www.m2north.com/'}
                                    target={'_blank'}
                                    className='md:flex flex-wrap break-all btn btn-ghost btn-md btn-outline normal-case gap-2'
                                >
                                    <ArrowTopRightOnSquareIcon className={'w-5 h-5'}/>
                                    M2North
                                </a>
                    
                                <div className='flex flex-col md:justify-end justify-center items-start md:items-center gap-1'>
                                    <div className='badge badge-xs md:badge-sm  font-bold'>
                                        2021 - Present
                                    </div>
                                </div>
                            </div>
                    
                            <div className='px-4'>
                                <ul className='list-disc'>
                                    <li>
                                        <span className='badge text-sm'>
                                            <strong className='mr-2'>
                                                Profit Rocket
                                            </strong>
                                        </span>
                                        <div className='divider my-0' />
                                        <ul className='list-decimal md:pl-8 pl-4 text-sm'>
                                            <li>
                                                <span className='flex flex-wrap mt-2'>
                                                    <p className='break-words text-sm'>
                                                        Architected, designed and implemented a real time data analysis tool capable
                                                        of using invoices
                                                        and credit notes to derive performance metrics for a customer, with a mean
                                                        response time precache
                                                        of 3.5s. The system aggregates volumes of up to 100GB per customer annually,
                                                        delivering
                                                        an interactive experience for end users by leveraging Java and Elasticsearch
                                                        for efficiency
                                                    </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <p className='break-words text-sm'>
                                                        Optimized and contributed to backend and completely re-designed and re-built
                                                        frontend of a legacy tool used to consolidate
                                                        multi-company sales and
                                                        inventory data to generate daily alerts that are prioritised into actionable
                                                        tasks in the hands of the right people at the right time.
                                                    </p>
                                                </span>
                                            </li>

                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <p className='break-words text-sm'>
                                                        Built an interactive web application using React, Redux and Tailwind with a modern UI for clients
                                                        to view real-time charting, create customized data widgets, and perform administrative tasks
                                                        from the interface, including team management, data exclusions and mapping updates.
                                                    </p>
                                                </span>
                                            </li>

                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <p className='break-words text-sm'>
                                                        Helped drive user traffic to a legacy system by over 400%. Reduced frontend response times by
                                                        12s per request and reduced system downtime by 200%, by purging unused data dynamically and
                                                        pre-performing more complex queries.
                                                    </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <p className='break-words text-sm'>
                                                        Reduced support query delays by 4 days per query, by building an internal administration panel
                                                        using TypeScript and React to provide dev-ops and support members with a tool to resolve
                                                        queries without the need of a developer.
                                                    </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <p className='break-words text-sm'>
                                                        Implemented comprehensive unit testing with Jest, ensuring robust deployments.
                                                    </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <p className='break-words text-sm'>
                                                        Implemented a dynamic caching layer using Redis to further reduce load times to less than 0.5s
                                                        when dealing with recently performed calculations.
                                                    </p>
                                                </span>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <span className='badge text-sm mt-4'>
                                            <strong className='mr-2 '>
                                                Order Portals
                                            </strong>
                                        </span>
                                        <div className='divider my-0' />
                                        <ul className='list-decimal md:pl-8 pl-4 text-sm'>
                                            <li>
                                                <span className='flex flex-wrap mt-2'>
                                                    <p className='break-words text-sm'>
                                                        Worked as part of a team in building portals and transaction pipelines from
                                                        supplier to distributor for UK-based clients. The project involved
                                                        automating the entire
                                                        procurement
                                                        process for a retailer, including Order Requisitions, Compliance
                                                        Information,
                                                        Order Confirmations, Packingslips etc.
                                                    </p>
                                                </span>
                                            </li>

                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <p className='break-words text-sm'>
                                                        Worked in a team of 4 developers on integrating third party SaaS providers (Sage, Shopify, Syspro, Stripe,
                                                        UPS and more) into custom order management portals. Portals were built using PHP, Laravel, Vue
                                                        and deployed on AWS using Elasti Beanstalk and Docker, with automated CI/CD through
                                                        Bitbucket pipelines and data persisted using PostgreSQL and Amazon RDS.
                                                    </p>
                                                </span>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <span className='badge text-sm mt-4'>
                                            <strong className='mr-2'>
                                                Other Miscellaneous Work
                                            </strong>
                                        </span>
                                        <div className='divider my-0' />
                                        <p className='text-sm'>
                                            <ul className={'list-disc md:pl-8 pl-4'}>
                                                <li>
                                                    <p>
                                                        <strong className='mr-2'>
                                                            Whatsapp Bots
                                                        </strong>
                                                        Using a combination of AWS Step Functions, AWS Lambda Functions and Twilio,
                                                        built multiple custom Whatsapp bots which allow customer's users to
                                                        interact with an existing system using a highly accessible interface. Worked
                                                        closely with partners to integrate their systems and ensure data collection was secure.
                                                        User data was abstracted to be hidden from our system while remaining available to the customer
                                                        themselves.
                                                    </p>
                                                </li>
                    
                                                <li>
                                                    <p>
                                                        <strong className="mr-2">
                                                            Web Scraping
                                                        </strong>
                                                        Developed multiple scraping bots which used AWS Lambda and Puppeteer to
                                                        enable our systems to log into and download data from existing interfaces,
                                                        without the need for customers to create API endpoints.
                                                    </p>
                                                </li>
                                            </ul>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                    
                            <div className='divider my-2' />
                    
                            <div className='flex md:flex-row flex-col gap-2 w-full justify-between md:items-center items-start'>
                                <a
                                    // href={'https://matchratings.io/'}
                                    // target={'_blank'}
                                    className='flex btn btn-outline normal-case cursor-default gap-2'
                                >
                                    <ChartBarIcon className='w-5 h-5' />
                                    Match Ratings
                                </a>
                    
                                <div className='badge font-bold md:badge-sm badge-xs'>
                                    2022 - 2023
                                </div>
                            </div>
                    
                            <div className='md:px-4 md:py-2'>
                                <div className='flex flex-wrap text-sm'>
                                    <p>
                                        Built a real time voting application for football fans to cast votes on their
                                        opinions of player performances after full time. The website garnered <span
                                        className={'badge badge-primary badge-sm font-mono'}>30k</span> page
                                        views within 40 days of going live, with over <span
                                        className={'badge badge-primary badge-sm font-mono'}>6.1k</span> visitors
                                        , <span
                                        className={'badge badge-primary badge-sm font-mono'}>1.26k</span> signed-up
                                        users,
                                        and over <span
                                        className={'badge badge-primary badge-sm font-mono'}>15k</span> votes cast on
                                        the web application.
                                        We partnered with a YouTube fan channel with almost <span
                                        className={'badge badge-primary badge-sm font-mono'}>700k</span> subscribers
                                        to use the system as part of their post-match analysis, and plan on growing the
                                        platform further by onboarding other fan channels.
                                        <br /><br />
                                        An inexhaustive list of technologies used to build matchratings.io is:
                                        <ul className='list-disc my-2 md:pl-8 pl-4'>
                                            <li>
                                                <strong>Backend</strong>: Python3, Django, Django Rest Framework,
                                                Celery, Nginx, Gunicorn
                                            </li>
                                            <li>
                                                <strong>Frontend</strong>: Typescript, ReactJS, Tailwind CSS
                                            </li>
                                            <li>
                                                <strong>Database</strong>: PostgreSQL
                                            </li>
                                            <li>
                                                <strong>Infrastructure</strong>: AWS EC2, AWS Amplify
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                    
                            <div className='divider md:my-2 my-0' />

                            <div
                                className='flex md:flex-row flex-col gap-2 w-full justify-between md:items-center items-start'>
                                <a
                                    className='flex btn btn-outline cursor-default normal-case gap-1'
                                >
                                    <PencilIcon className='w-5 h-5' />
                                    Private Tutor
                                </a>

                                <div className='badge font-bold md:badge-sm badge-xs'>
                                    2016 - 2019
                                </div>
                            </div>

                            <div className='px-4 md:mt-0 mt-2'>
                            <div className='flex flex-wrap text-sm'>
                                    <p>
                                        Worked as a private tutor for <strong>Physical Science</strong> and <strong>AP
                                        Mathematics</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='lg:col-span-6 col-span-12 bg-base-300 card'>
                        <div className={`card-body group md:px-12 md:py-8 p-4  ${muted_border}`}>
                            <div className='flex w-full justify-between items-center'>
                                <AnimatedBorder />

                                <h3 className='card-title'>
                                    Education
                                </h3>

                                <div className='flex items-center gap-2'>
                                    <div className='divider divider-horizontal mx-0' />
                                    <a
                                        className='btn btn-circle btn-ghost btn-sm flex justify-center items-center tooltip tooltip-left normal-case'
                                        data-tip={'Download Degree Certificate'}
                                        href={'https://liam-burks.s3.eu-west-1.amazonaws.com/liam-burks-degree.pdf'}
                                        target={'_blank'}
                                    >
                                        <BuildingLibraryIcon className='w-5 h-5' />
                                    </a>

                                    <a className='btn btn-circle btn-ghost btn-sm flex justify-center items-center tooltip tooltip-left normal-case'
                                       data-tip={'Download Matric Results'}
                                       href={'https://liam-burks.s3.eu-west-1.amazonaws.com/liam-burks-matric.zip'}
                                       target={'_blank'}
                                    >
                                        <BookOpenIcon className='w-5 h-5' />
                                    </a>
                                </div>
                            </div>

                            <div className='divider my-0' />
                            <div
                                className='flex md:flex-row flex-col gap-2 w-full justify-between md:items-center items-start'>
                                <a
                                    href={'https://www.up.ac.za/'}
                                    target={'_blank'}
                                    className='flex btn btn-ghost btn-outline btn-md normal-case'
                                >
                                    <AcademicCapIcon className='w-5 h-5 mr-2' />
                                    University of Pretoria
                                </a>

                                <div className='badge font-bold md:badge-sm badge-xs'>
                                    2016 - 2021
                                </div>
                            </div>
                            <label
                                htmlFor={'courses_modal'}
                                className='badge badge-primary badge-lg h-auto text-sm w-full font-bold md:my-0 my-2 tooltip tooltip-bottom cursor-pointer'
                                data-tip={"Details"}
                            >
                                Bachelor of Engineering: Computer Engineering
                            </label>
                    
                            <div className='px-4'>
                                <ul className='list-disc'>
                                    <li>
                                        <span className='flex-wrap break-words text-sm'>
                                            <strong className="mr-2">
                                                EPR 402
                                            </strong>
                                            Final Year Project
                                        </span>
                                        <div className='divider my-0' />
                                        <p className='text-sm'>
                                            Completed in partial fulfillment of a <strong>Bachelor of Engineering:
                                            Computer Engineering</strong>:<br /><br />
                                            Built a <strong>Query by Humming</strong> Android application capable of
                                            receiving vocal input from a user in the form of singing/humming and
                                            detecting what song the user sang withing 1.2 seconds, with an F-measure
                                            Score of 95%. <br /><br />
                                            Some notable topics covered by this project are the <strong>Fast Fourier
                                            Transform (FFT)</strong>, <strong>Autocorrelation</strong>, <strong>Melody
                                            Contour Representation</strong>, <strong>Suffix Trees</strong>, and <strong>Approximate
                                            String Matching</strong> using edit distance. <br /><br />
                                            This project was completed with <span
                                            className='font-bold'>distinction</span>, with a final average of <span
                                            className='badge badge-success font-mono'>77%</span>.
                                        </p>
                                    </li>
                    
                                    <li>
                                        <div className='badge text-sm mt-4'>
                                            Other Notable Modules
                                        </div>
                                        <div className='divider my-0' />
                                        <ul>
                                            <li>
                                                <span className='flex flex-wrap'>
                                                    <span className='badge font-bold mr-2'>
                                                        ERP 420
                                                    </span>
                                                    <p className='break-words text-sm'>
                                                        <span className='italic'>Research Project</span>: Implementing
                                                        a Quaternion based Extended Kalman Filter for real-time
                                                        orientation estimation on a rigid body in 3D space. Code was
                                                        written in <strong>C</strong>.
                                                    </p>
                                                </span>
                                            </li>
                    
                                            <li>
                                                <span className='flex flex-wrap mt-4'>
                                                    <span className='badge font-bold mr-2'>
                                                        EAI 322
                                                    </span>
                                                    <p className='break-words text-sm'>
                                                        <span className='italic'>Artificial Intelligence</span>:
                                                        Designed, implemented and compared solutions to a standard problem
                                                        using various artificial intelligence fields of study.
                                                        Solutions involved but were not limited to <strong>Neural networks</strong>, <strong>Decision trees</strong> and <strong>Genetic algorithms</strong>.
                                                        Code was written in <strong>Python</strong>.
                                                    </p>
                                                </span>
                                            </li>
                    
                                            <li>
                                                <span className='flex flex-wrap mt-4'>
                                                    <span className='badge font-bold mr-2'>
                                                        EPE 321
                                                    </span>
                                                    <p className='break-words text-sm'>
                                                        <span className='italic'>Software Engineering</span>:
                                                        Worked as groups of to develop a functional, cross-network game
                                                        of bridge. The course examined modern design principals, documentation,
                                                        project specification, scrum management and other software engineering principles.
                                                        Code was written in <strong>C++</strong>.
                                                    </p>
                                                </span>
                                            </li>
                    
                                            <li>
                                                <span className='flex flex-wrap mt-4'>
                                                    <span className='badge font-bold mr-2'>
                                                        ERD 320
                                                    </span>
                                                    <p className='break-words text-sm'>
                                                        <span className='italic'>Computer Engineering Design</span>: Built a prototype surveying bot using a PIC32F45K20 microcontroller, ultrasonic sensor, stepper motor, custom built power supply, brushless DC motor, WiFi module and various other components.
                                                        Code was written in <strong>C</strong>.
                                                    </p>
                                                </span>
                                            </li>
                    
                                            <li>
                                                <span className='flex flex-wrap mt-4'>
                                                    <span className='badge font-bold mr-2'>
                                                        COS 212
                                                    </span>
                                                    <p className='break-words text-sm'>
                                                        <span className='italic'>Data Structures and Algorithms</span>:
                                                        Implemented algorithms for sorting, searching, traversing,
                                                        packing and game playing, with an emphasis on comparative
                                                        implementations and efficiency. Some examples are <strong>binary trees</strong>, <strong>b* trees</strong>, <strong>graphs</strong>, <strong>digraphs</strong> and <strong>hash tables</strong>.
                                                        Code was written in <strong>Java</strong>.
                                                    </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='flex flex-wrap mt-4'>
                                                    <span className='badge font-bold mr-2'>
                                                        COS 122
                                                    </span>
                                                    <p className='break-words text-sm'>
                                                        <span className='italic'>Operating Systems</span>:
                                                        Fundamental concepts of modern operating systems in terms of
                                                        their structure and their mechanisms.
                                                    </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='flex flex-wrap mt-4'>
                                                    <div className='break-words text-sm'>
                                                        A full list of the courses taken to fulfill this degree can be seen <label className="link link-primary"
                                                        htmlFor='courses_modal'>here</label>.
                                                        {/*<a className='underline cursor-pointer text-info h-0 normal-case' href={'/liam-burks-up-acad-rcd.pdf'} target={'_blank'}>results</a>*/}
                                                    </div>
                                                </span>
                                            </li>
                                        </ul>
                                    </li>
                    
                                    <li>
                                        <p className='text-sm'>
                                            Was invited to join the <strong>Golden Key</strong> society for achieving in
                                            the top <span className="badge badge-success font-mono">10%</span> of the degree.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                    
                            <div className='divider my-2' />

                            <div
                                className='flex md:flex-row flex-col gap-2 w-full justify-between md:items-center items-start'>
                                <a
                                    href={'https://www.stbenedicts.co.za/'}
                                    target={'_blank'}
                                    className='flex btn btn-outline normal-case'
                                >
                                    <BookOpenIcon className='w-5 h-5 mr-2' />
                                    St Benedict's College
                                </a>

                                <div className='badge font-bold md:badge-sm badge-xs'>
                                    2003 - 2015
                                </div>
                            </div>

                            <div className='badge badge-primary badge-lg text-sm w-full mb-3 font-bold md:my-0 my-2'>
                                IEB / National Senior Certificate
                            </div>
                    
                            <div className='px-4'>
                                <ul className='list-disc text-sm'>
                                    <li>
                                        <div className='flex flex-wrap items-center'>
                                            <p>
                                                <span className='badge badge-success font-mono mr-1'>89.5%</span>
                                                average with
                                                <span className='badge badge-success font-mono mx-1'>8</span>
                                                distinctions.
                                            </p>
                                        </div>
                                        <div className='flex flex-col justify-start items-start'>
                                            <div className='divider my-2' />
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>96%</span>
                                                <p className='font-bold'>
                                                    Mathematics
                                                </p>
                                            </div>
                    
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>95%</span>
                                                <p className='font-bold'>
                                                    Physical Sciences
                                                </p>
                                            </div>
                    
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>93%</span>
                                                <p className='font-bold'>
                                                    Information Technology
                                                </p>
                                            </div>
                    
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>91%</span>
                                                <p className='font-bold'>
                                                    Advanced Mathematics
                                                </p>
                                            </div>
                    
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>89%</span>
                                                <p className='font-bold'>
                                                    Life Orientation
                                                </p>
                                            </div>
                    
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>87%</span>
                                                <p className='font-bold'>
                                                    Geography
                                                </p>
                                            </div>
                    
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>83%</span>
                                                <p className='font-bold'>
                                                    English
                                                </p>
                                            </div>
                    
                                            <div className='flex flex-wrap items-center'>
                                                <span
                                                    className='badge badge-sm badge-success badge-outline font-mono w-16 mr-4'>82%</span>
                                                <p className='font-bold'>
                                                    Afrikaans
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                    
                                    <li>
                                        <div className='flex flex-wrap items-center mt-4'>
                                            <p>
                                                <span className='font-bold mr-2'>
                                                    Silver Academic Dux
                                                </span>
                                                for achieving the second highest average in the grade in Matric.
                                            </p>
                                        </div>
                                    </li>
                    
                                    <li>
                                        <div className='flex flex-wrap items-center mt-4'>
                                            <p>
                                                <span className='font-bold mr-2'>
                                                    John Marais Overall Dux
                                                </span>
                                                awarded to the most well rounded student across academic, athletic and
                                                cultural disciplines.
                                            </p>
                                        </div>
                                    </li>
                    
                                    <li>
                                        <div className='flex flex-wrap items-center mt-4'>
                                            <p>
                                                <span className='font-bold mr-2'>
                                                    Honours Blazer
                                                </span>
                                                awarded for achieving full colours in Academics, Athletics and Hockey.
                                            </p>
                                        </div>
                                    </li>
                    
                                    <li>
                                        <div className='flex flex-wrap items-center mt-4'>
                                            <p>
                                                Selected
                                                <span className='font-bold mx-2'>
                                                    Deputy Head Boy
                                                </span>
                                                in matric.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;